import { API } from "../../backend";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import { SET_CURRENT_USER, USER_LOADING, USER_LOADED } from "../types";
import { notification } from "antd";

const Notifiction = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(`${API}/login/`, userData)
    .then((res) => {
      const is_superuser = res.data.is_superuser;
      const is_staff = res.data.is_staff;
      if (is_superuser || is_staff) {
        const { access } = res.data;
        // Save token to localStorage
        localStorage.setItem("jwtToken", access);
        localStorage.setItem("userData", JSON.stringify(res.data));
        // Set token to Auth header
        setAuthToken(localStorage.getItem("jwtToken"));
        // Set current user
        dispatch(setCurrentUser(res.data));
        // login message on succes
        Notifiction("success", "Login Success", "Logged in Successfully");
        // set loading to false
        dispatch(setUserLoaded());
      } else {
        Notifiction("warning", "Login Warning", "User not authorized to login");
        dispatch(setUserLoaded());
      }
    })
    .catch((err) => {
      // error message
      Notifiction(
        "error",
        "Login Error",
        "Unauthorized access, please enter valid credentials"
      );
      dispatch(setUserLoaded());
    });
};

// Set logged in user
export const setCurrentUser = (userData) => {
  return {
    type: SET_CURRENT_USER,
    payload: userData,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// User loaded
export const setUserLoaded = () => {
  return {
    type: USER_LOADED,
  };
};

// Log user out
export const logoutUser = (history) => (dispatch) => {
  // Remove token,userData from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("userData");
  // Remove auth header for future reques
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  if (history) {
    history.push("/");
  }
  Notifiction("warning", "Logout Success", "Logged out Successfully");
};
