import React, { Component, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../app/loader";
import Store from "../redux/store";
import { setCurrentUser } from "../redux/actions/authAction";
import setAuthToken from "../utils/setAuthToken";
import PrivateRoute from "./PrivateRoute";

const Home = React.lazy(() => import("../app/components/home"));
const PlasmaDonateForm = React.lazy(() =>
  import("../app/components/plasma/donate")
);
const PlasmaRequestForm = React.lazy(() =>
  import("../app/components/plasma/request")
);
const InformationForm = React.lazy(() =>
  import("../app/components/resources/information")
);
const VerifiedLeads = React.lazy(() =>
  import("../app/components/resources/leads")
);
const NewsAndFacts = React.lazy(() => import("../app/components/news"));

const Login = React.lazy(() => import("../app/admin/Login"));

const Dashboard = React.lazy(() =>
  import("../app/admin/dashboard/resource/dashboard")
);
const AdminProfile = React.lazy(() => import("../app/admin/dashboard/profile"));
const AdminResourceLeads = React.lazy(() =>
  import("../app/admin/dashboard/resource/leads")
);
const AdminResourceForm = React.lazy(() =>
  import("../app/admin/dashboard/resource/info")
);

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info
  const user = JSON.parse(localStorage.userData);
  // Set user and isAuthenticated
  Store.dispatch(setCurrentUser(user));
}

class App extends Component {
  render() {
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path={["/home", "/"]} component={Home} />
          <Route exact path="/plasma/donate" component={PlasmaDonateForm} />
          <Route exact path="/plasma/request" component={PlasmaRequestForm} />
          <Route
            exact
            path="/resources/information"
            component={InformationForm}
          />
          <Route
            exact
            path="/resources/verified-leads"
            component={VerifiedLeads}
          />
          <Route exact path="/news" component={NewsAndFacts} />
          <Route exact path="/admin/login" component={Login} />
          {/* private routes */}
          <PrivateRoute exact path="/admin/profile" component={AdminProfile} />
          <PrivateRoute exact path="/admin/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/admin/resources/leads"
            component={AdminResourceLeads}
          />
          <PrivateRoute
            exact
            path="/admin/resources/add"
            component={AdminResourceForm}
          />
          <PrivateRoute
            exact
            path="/admin/resources/edit/:id"
            component={AdminResourceForm}
          />
        </Switch>
      </Suspense>
    );
  }
}

export default App;
